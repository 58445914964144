import { useRedirect, Edit, SimpleForm, useTranslate, BooleanInput } from 'react-admin'

import AvailableProductsSelect from '../../components/formfields/AvailableProductsSelect'
import PackageProductEditToolbar from './PackageProductEditToolbar'

const PackageProductEdit = (props) => {
    const translate = useTranslate()
    const redirect = useRedirect()

    const RESOURCE = 'PackageProduct'
    const onSuccess = (response: any) => {
        redirect('edit', '/Package', response.data?.package_id)
    }

    return (
        <Edit
            basePath={props.basepath}
            resource={RESOURCE}
            undoable={false} // This makes sure onSuccess works and enables a confimation dialog: https://stackoverflow.com/questions/62842637/react-admin-onsuccess-does-not-work-properly-on-edit-component
            onSuccess={onSuccess}
            title={translate(`manager.resources.${RESOURCE}.edit`)}
            {...props}
        >
            <SimpleForm
                initialValues={{ package_id: props?.location?.state?.package_id }}
                toolbar={<PackageProductEditToolbar resource={RESOURCE} />}
            >
                <AvailableProductsSelect disabled isEdit />
                <BooleanInput
                    row
                    source="is_main"
                    label={translate(`manager.resources.${RESOURCE}.is_main`)}
                />
                <BooleanInput
                    row
                    source="is_optional"
                    label={translate(`manager.resources.${RESOURCE}.is_optional`)}
                />
                <BooleanInput
                    row
                    source="is_included"
                    label={translate(`manager.resources.${RESOURCE}.is_included`)}
                />
                <BooleanInput
                    row
                    source="active"
                    label={translate('manager.resources.general.published')}
                />
            </SimpleForm>
        </Edit>
    )
}

export default PackageProductEdit
