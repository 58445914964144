import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateRetailerTagMutationVariables = Types.Exact<{
  tag: Types.Scalars['String'];
}>;


export type CreateRetailerTagMutation = { __typename?: 'Mutation', createRetailerTag?: { __typename?: 'retailersRetailerTag', tag: string, id: string } | null | undefined };


export const CreateRetailerTagDocument = gql`
    mutation CreateRetailerTag($tag: String!) {
  createRetailerTag: retailersCreateRetailerTag(tag: $tag) {
    tag
    id
  }
}
    `;
export type CreateRetailerTagMutationFn = Apollo.MutationFunction<CreateRetailerTagMutation, CreateRetailerTagMutationVariables>;

/**
 * __useCreateRetailerTagMutation__
 *
 * To run a mutation, you first call `useCreateRetailerTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRetailerTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRetailerTagMutation, { data, loading, error }] = useCreateRetailerTagMutation({
 *   variables: {
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useCreateRetailerTagMutation(baseOptions?: Apollo.MutationHookOptions<CreateRetailerTagMutation, CreateRetailerTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRetailerTagMutation, CreateRetailerTagMutationVariables>(CreateRetailerTagDocument, options);
      }
export type CreateRetailerTagMutationHookResult = ReturnType<typeof useCreateRetailerTagMutation>;
export type CreateRetailerTagMutationResult = Apollo.MutationResult<CreateRetailerTagMutation>;
export type CreateRetailerTagMutationOptions = Apollo.BaseMutationOptions<CreateRetailerTagMutation, CreateRetailerTagMutationVariables>;
