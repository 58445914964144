import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RetailersRetailerClassesQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.RetailersRetailerClassFilterInput>;
}>;


export type RetailersRetailerClassesQuery = { __typename?: 'Query', retailersRetailerClasses?: { __typename?: 'retailersRetailerClassPaginator', data: Array<{ __typename?: 'retailersRetailerClass', id: string, name: string }> } | null | undefined };


export const RetailersRetailerClassesDocument = gql`
    query RetailersRetailerClasses($filter: retailersRetailerClassFilterInput) {
  retailersRetailerClasses(filter: $filter) {
    data {
      id
      name
    }
  }
}
    `;

/**
 * __useRetailersRetailerClassesQuery__
 *
 * To run a query within a React component, call `useRetailersRetailerClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetailersRetailerClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetailersRetailerClassesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRetailersRetailerClassesQuery(baseOptions?: Apollo.QueryHookOptions<RetailersRetailerClassesQuery, RetailersRetailerClassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RetailersRetailerClassesQuery, RetailersRetailerClassesQueryVariables>(RetailersRetailerClassesDocument, options);
      }
export function useRetailersRetailerClassesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RetailersRetailerClassesQuery, RetailersRetailerClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RetailersRetailerClassesQuery, RetailersRetailerClassesQueryVariables>(RetailersRetailerClassesDocument, options);
        }
export type RetailersRetailerClassesQueryHookResult = ReturnType<typeof useRetailersRetailerClassesQuery>;
export type RetailersRetailerClassesLazyQueryHookResult = ReturnType<typeof useRetailersRetailerClassesLazyQuery>;
export type RetailersRetailerClassesQueryResult = Apollo.QueryResult<RetailersRetailerClassesQuery, RetailersRetailerClassesQueryVariables>;
