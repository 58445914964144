import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RetailersExtensionAttributesSchemaQueryVariables = Types.Exact<{
  entityType: Types.Scalars['String'];
}>;


export type RetailersExtensionAttributesSchemaQuery = { __typename?: 'Query', retailersExtensionAttributesSchema?: string | null | undefined };


export const RetailersExtensionAttributesSchemaDocument = gql`
    query RetailersExtensionAttributesSchema($entityType: String!) {
  retailersExtensionAttributesSchema(entity_type: $entityType)
}
    `;

/**
 * __useRetailersExtensionAttributesSchemaQuery__
 *
 * To run a query within a React component, call `useRetailersExtensionAttributesSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetailersExtensionAttributesSchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetailersExtensionAttributesSchemaQuery({
 *   variables: {
 *      entityType: // value for 'entityType'
 *   },
 * });
 */
export function useRetailersExtensionAttributesSchemaQuery(baseOptions: Apollo.QueryHookOptions<RetailersExtensionAttributesSchemaQuery, RetailersExtensionAttributesSchemaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RetailersExtensionAttributesSchemaQuery, RetailersExtensionAttributesSchemaQueryVariables>(RetailersExtensionAttributesSchemaDocument, options);
      }
export function useRetailersExtensionAttributesSchemaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RetailersExtensionAttributesSchemaQuery, RetailersExtensionAttributesSchemaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RetailersExtensionAttributesSchemaQuery, RetailersExtensionAttributesSchemaQueryVariables>(RetailersExtensionAttributesSchemaDocument, options);
        }
export type RetailersExtensionAttributesSchemaQueryHookResult = ReturnType<typeof useRetailersExtensionAttributesSchemaQuery>;
export type RetailersExtensionAttributesSchemaLazyQueryHookResult = ReturnType<typeof useRetailersExtensionAttributesSchemaLazyQuery>;
export type RetailersExtensionAttributesSchemaQueryResult = Apollo.QueryResult<RetailersExtensionAttributesSchemaQuery, RetailersExtensionAttributesSchemaQueryVariables>;
