import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateRetailerClassRetailerTagMutationVariables = Types.Exact<{
  retailerTagId: Types.Scalars['Int'];
  retailerClassId: Types.Scalars['Int'];
}>;


export type CreateRetailerClassRetailerTagMutation = { __typename?: 'Mutation', createRetailerClassRetailerTag?: { __typename?: 'retailersRetailerClassRetailerTag', retailer_tag_id: number, retailer_class_id: number } | null | undefined };


export const CreateRetailerClassRetailerTagDocument = gql`
    mutation CreateRetailerClassRetailerTag($retailerTagId: Int!, $retailerClassId: Int!) {
  createRetailerClassRetailerTag: retailersCreateRetailerClassRetailerTag(
    retailer_tag_id: $retailerTagId
    retailer_class_id: $retailerClassId
  ) {
    retailer_tag_id
    retailer_class_id
  }
}
    `;
export type CreateRetailerClassRetailerTagMutationFn = Apollo.MutationFunction<CreateRetailerClassRetailerTagMutation, CreateRetailerClassRetailerTagMutationVariables>;

/**
 * __useCreateRetailerClassRetailerTagMutation__
 *
 * To run a mutation, you first call `useCreateRetailerClassRetailerTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRetailerClassRetailerTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRetailerClassRetailerTagMutation, { data, loading, error }] = useCreateRetailerClassRetailerTagMutation({
 *   variables: {
 *      retailerTagId: // value for 'retailerTagId'
 *      retailerClassId: // value for 'retailerClassId'
 *   },
 * });
 */
export function useCreateRetailerClassRetailerTagMutation(baseOptions?: Apollo.MutationHookOptions<CreateRetailerClassRetailerTagMutation, CreateRetailerClassRetailerTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRetailerClassRetailerTagMutation, CreateRetailerClassRetailerTagMutationVariables>(CreateRetailerClassRetailerTagDocument, options);
      }
export type CreateRetailerClassRetailerTagMutationHookResult = ReturnType<typeof useCreateRetailerClassRetailerTagMutation>;
export type CreateRetailerClassRetailerTagMutationResult = Apollo.MutationResult<CreateRetailerClassRetailerTagMutation>;
export type CreateRetailerClassRetailerTagMutationOptions = Apollo.BaseMutationOptions<CreateRetailerClassRetailerTagMutation, CreateRetailerClassRetailerTagMutationVariables>;
