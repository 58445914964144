import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RetailersRetailerTagsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.RetailersRetailerTagFilterInput>;
  orderBy?: Types.InputMaybe<Array<Types.RetailersQueryRetailerTagsOrderByOrderByClause> | Types.RetailersQueryRetailerTagsOrderByOrderByClause>;
}>;


export type RetailersRetailerTagsQuery = { __typename?: 'Query', retailersRetailerTags?: { __typename?: 'retailersRetailerTagPaginator', data: Array<{ __typename?: 'retailersRetailerTag', id: string, tag: string, created_at: any, updated_at: any, retailers: Array<{ __typename?: 'retailersRetailer', id: string, name: string } | null | undefined> }> } | null | undefined };


export const RetailersRetailerTagsDocument = gql`
    query RetailersRetailerTags($filter: retailersRetailerTagFilterInput, $orderBy: [retailersQueryRetailerTagsOrderByOrderByClause!]) {
  retailersRetailerTags(filter: $filter, orderBy: $orderBy) {
    data {
      id
      tag
      created_at
      updated_at
      retailers {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useRetailersRetailerTagsQuery__
 *
 * To run a query within a React component, call `useRetailersRetailerTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetailersRetailerTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetailersRetailerTagsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useRetailersRetailerTagsQuery(baseOptions?: Apollo.QueryHookOptions<RetailersRetailerTagsQuery, RetailersRetailerTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RetailersRetailerTagsQuery, RetailersRetailerTagsQueryVariables>(RetailersRetailerTagsDocument, options);
      }
export function useRetailersRetailerTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RetailersRetailerTagsQuery, RetailersRetailerTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RetailersRetailerTagsQuery, RetailersRetailerTagsQueryVariables>(RetailersRetailerTagsDocument, options);
        }
export type RetailersRetailerTagsQueryHookResult = ReturnType<typeof useRetailersRetailerTagsQuery>;
export type RetailersRetailerTagsLazyQueryHookResult = ReturnType<typeof useRetailersRetailerTagsLazyQuery>;
export type RetailersRetailerTagsQueryResult = Apollo.QueryResult<RetailersRetailerTagsQuery, RetailersRetailerTagsQueryVariables>;
