import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RetailersImportRetailerClassRepresentativesMutationVariables = Types.Exact<{
  file: Types.Scalars['retailersUpload'];
}>;


export type RetailersImportRetailerClassRepresentativesMutation = { __typename?: 'Mutation', retailersImportRetailerClassRepresentatives?: boolean | null | undefined };


export const RetailersImportRetailerClassRepresentativesDocument = gql`
    mutation RetailersImportRetailerClassRepresentatives($file: retailersUpload!) {
  retailersImportRetailerClassRepresentatives(file: $file)
}
    `;
export type RetailersImportRetailerClassRepresentativesMutationFn = Apollo.MutationFunction<RetailersImportRetailerClassRepresentativesMutation, RetailersImportRetailerClassRepresentativesMutationVariables>;

/**
 * __useRetailersImportRetailerClassRepresentativesMutation__
 *
 * To run a mutation, you first call `useRetailersImportRetailerClassRepresentativesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetailersImportRetailerClassRepresentativesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retailersImportRetailerClassRepresentativesMutation, { data, loading, error }] = useRetailersImportRetailerClassRepresentativesMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useRetailersImportRetailerClassRepresentativesMutation(baseOptions?: Apollo.MutationHookOptions<RetailersImportRetailerClassRepresentativesMutation, RetailersImportRetailerClassRepresentativesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RetailersImportRetailerClassRepresentativesMutation, RetailersImportRetailerClassRepresentativesMutationVariables>(RetailersImportRetailerClassRepresentativesDocument, options);
      }
export type RetailersImportRetailerClassRepresentativesMutationHookResult = ReturnType<typeof useRetailersImportRetailerClassRepresentativesMutation>;
export type RetailersImportRetailerClassRepresentativesMutationResult = Apollo.MutationResult<RetailersImportRetailerClassRepresentativesMutation>;
export type RetailersImportRetailerClassRepresentativesMutationOptions = Apollo.BaseMutationOptions<RetailersImportRetailerClassRepresentativesMutation, RetailersImportRetailerClassRepresentativesMutationVariables>;
