import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RetailersRetailersQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type RetailersRetailersQuery = { __typename?: 'Query', retailersRetailers?: { __typename?: 'retailersRetailerPaginator', data: Array<{ __typename?: 'retailersRetailer', code: string, id: string, name: string }> } | null | undefined };


export const RetailersRetailersDocument = gql`
    query RetailersRetailers($first: Int) {
  retailersRetailers(first: $first) {
    data {
      code
      id
      name
    }
  }
}
    `;

/**
 * __useRetailersRetailersQuery__
 *
 * To run a query within a React component, call `useRetailersRetailersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetailersRetailersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetailersRetailersQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useRetailersRetailersQuery(baseOptions?: Apollo.QueryHookOptions<RetailersRetailersQuery, RetailersRetailersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RetailersRetailersQuery, RetailersRetailersQueryVariables>(RetailersRetailersDocument, options);
      }
export function useRetailersRetailersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RetailersRetailersQuery, RetailersRetailersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RetailersRetailersQuery, RetailersRetailersQueryVariables>(RetailersRetailersDocument, options);
        }
export type RetailersRetailersQueryHookResult = ReturnType<typeof useRetailersRetailersQuery>;
export type RetailersRetailersLazyQueryHookResult = ReturnType<typeof useRetailersRetailersLazyQuery>;
export type RetailersRetailersQueryResult = Apollo.QueryResult<RetailersRetailersQuery, RetailersRetailersQueryVariables>;
